//HotVideo.vue
import { getFormatDate, getBeforeMonthDay, getBeforeWeek, getBeforeMonth } from "./formatDate"
const HOTVIDEO = {
  diggOptions: [
    {
        label: '全部',
        value: -1
    },
    {
        label: '<1万',
        value: '0-10000'
    },
    {
        label: '1万~5万',
        value: '10000-50000'
    },
    {
        label: '5万~10万',
        value: '50000-100000'
    },
    {
        label: '10万~50万',
        value: '100000-500000'
    },
    {
        label: '50万~100万',
        value: '500000-1000000'
    },
    {
        label: '100万~200万',
        value: '1000000-2000000'
    },
    {
        label: '>200万',
        value: '2000000-'
    },
  ],
  timeFilterOptions: {
    hour: [
      {
        label: "00.00-06.00",
        value: "0-6"
      },
      {
        label: "06.00-12.00",
        value: "6-12"
      },
      {
        label: "12.00-18.00",
        value: "12-18"
      },
      {
        label: "18.00-24.00",
        value: "18-24"
      }
    ],
    day: getBeforeMonthDay(),
    week: getBeforeWeek(),
    month: getBeforeMonth()
  },
  durationOptions: [
    {
        label: '不限',
        value: -1
    },
    {
        label: '<15秒',
        value: '0-15'
    },
    {
        label: '15~30秒',
        value: '15-30'
    },
    {
        label: '30~60秒',
        value: '30-60'
    },
    {
        label: '>60秒',
        value: '60-'
    },
  ],
  genderOptions: [
    {
        label: '全部',
        value: -1
    },
    {
        label: '男观众多',
        value: '0'
    },
    {
        label: '女观众多',
        value: '1'
    },
  ],
  ageOptions: [
    {
        label: '全部',
        value: -1
    },
    {
        label: '6-17',
        value: '1'
    },
    {
        label: '18-24',
        value: '2'
    },
    {
        label: '25-30',
        value: '3'
    },
    {
        label: '31-35',
        value: '4'
    },
    {
        label: '36-40',
        value: '5'
    },
    {
        label: '41+ ',
        value: '6'
    },
  ]
}

export {
  HOTVIDEO
}