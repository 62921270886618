<template>
  <div class="company-contain" v-loading="loading">
    <div class="head-box">
      <!--筛选条件-->
      <div class="category-list">
        <div class="cate-left">分类：</div>
        <div class="cate-content">
          <template v-for="(cateItem, cateIndex) in typeList">
            <div
              class="cate-item"
              @click="chooseTag(cateItem)"
              :class="cateItem | categoryStyle(params.star_category)"
              :key="`categoryList_${cateIndex}`"
            >
              {{ cateItem }}
            </div>
          </template>
        </div>
      </div>
      <div class="filter-box">
        <!-- <div class="filter-item">
                    <span class="filter-title">点赞数：</span>
                    <el-select v-model="digg" placeholder="请选择" @change="getAllClass">
                        <el-option
                                v-for="item in diggOptions"
                                :key="item.label"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="filter-item">
                    <span class="filter-title">视频时长：</span>
                    <el-select v-model="duration" placeholder="请选择" @change="getAllClass">
                        <el-option
                                v-for="item in durationOptions"
                                :key="item.label"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </div> -->
      </div>
      <!-- <div class="filter-box">
                <div class="filter-item">
                    <span class="filter-title">观众画像筛选：</span>
                    <span class="filter-title light-title">男女比例：</span>
                    <el-select v-model="gender_type" placeholder="请选择" @change="getAllClass">
                        <el-option
                                v-for="item in genderOptions"
                                :key="item.label"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="filter-item">
                    <span class="filter-title light-title">主要年龄：</span>
                    <el-select v-model="age_type" placeholder="请选择" @change="getAllClass">
                        <el-option
                                v-for="item in ageOptions"
                                :key="item.label"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div> -->
      <div class="time-box" style="padding-bottom: 20px; padding-left: 20px">
        <div class="day-type">
          <el-radio-group class="radio-group" v-model="params.day_type" size="small" @change="getListByTime">
            <el-radio-button label="hour">小时榜</el-radio-button>
            <el-radio-button label="day">日榜</el-radio-button>
            <el-radio-button label="week">周榜</el-radio-button>
            <el-radio-button label="month">月榜</el-radio-button>
          </el-radio-group>
        </div>
        <div class="time-filter-box">
          <el-select class="list-type-box" size="mini" v-model="params.day" placeholder="请选择" @change="getAllClass()">
            <el-option v-for="item in currentListType" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </div>
        <el-input clearable @change="getAllClass(false)"  prefix-icon="el-icon-search" class="el-int"  placeholder="请输入视频名称" v-model="keyword" size="small"></el-input>

      </div>
    </div>

    <el-table
      v-if="fullTaskList.length > 0"
      :data="fullTaskList"
      class="college-table"
      size="medium"
      v-el-table-infinite-scroll="load"
      :header-cell-style="{ fontWeight: 'Regular', color: '#333', background: '#fff' }"
      :cell-style="{ fontSize: '12px', color: '#333' }"
      @sort-change="sortChange"
      height="400"
      ref="multipleTable"

    >
      <el-table-column prop="task_id" label="视频作品" align="left" width="700" class-name="student-name">
        <template slot-scope="scope">
          <div @click="changeToDetail(scope.row)" class="videoBox" style="display: flex; cursor: pointer">
            <div class="picBox">
              <img style="width: 90px; height: 120px" :src="scope.row.aweme_cover" />
            </div>
            <div class="right-row-content">
              <div style="font-size: 14px">{{ scope.row.aweme_title }}</div>
              <div class="hot-word-content">
                <!-- <span>热词：</span> -->
                <!-- <span v-if="scope.row.hot_words_info.length == 0">无</span>
                                <span v-else style="margin-left: 5px;"
                                      v-for="item in scope.row.hot_words_info" :key="item">{{item}}</span> -->
              </div>
              <div>
                <span>视频时长：</span>
                <span>{{ Math.floor(scope.row.duration / 1000) }}秒</span>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="nickname" width="200" label="播主" align="center" class-name="student-name">
        <template slot-scope="scope">
          <p class="student-name-title">{{ scope.row.nickname }}</p>
          <p class="student-name-title">{{ scope.row.aweme_create_time }}</p>
        </template>
      </el-table-column>
      <el-table-column prop="digg_count" label="点赞总数" align="center" class-name="student-name" sortable="custom">
        <template slot-scope="scope">
          <span class="student-name-title">{{ scope.row.digg_count }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="comment_count" label="评论总数" align="center" class-name="student-name" sortable="custom">
        <template slot-scope="scope">
          <span class="student-name-title">{{ scope.row.comment_count }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="share_count" label="转发总数" align="center" class-name="student-name" sortable="custom">
        <template slot-scope="scope">
          <span class="student-name-title">{{ scope.row.share_count }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250" align="center">
        <template slot-scope="scope">
          <el-button type="primary" circle @click="changeToDetail(scope.row)" size="small">
            <i class="iconfont">&#xe6a1;</i>
          </el-button>
          <!--                    <el-button type="success" circle size="small" @click="jumpToFansData(scope.row)">-->
          <!--                        <i class="iconfont">&#xe647;</i>-->
          <!--                    </el-button>-->
          <el-button type="danger" circle @click="jumpToVideo(scope.row)" size="small">
            <i class="iconfont">&#xe63a;</i>
          </el-button>
        </template>
      </el-table-column>
      <template #append>
        <div class="scroll-hint" v-if="showScrollLoading">{{ scrollHint }}</div>
      </template>
    </el-table>
    <div class="page-content">
      <!-- <el-pagination
                    class="pages-center"
                    style="padding: 10px;"
                    :current-page="adminPages.currentPageNum"
                    :page-size="adminPages.eachPageNum"
                    layout="prev, pager, next, jumper"
                    :total="adminPages.total"
                    @current-change="adminCurrentChange"
            ></el-pagination> -->
    </div>
  </div>
</template>

<script>
import { Loading } from "element-ui";
import elTableInfiniteScroll from "el-table-infinite-scroll";
import { newAccountGetVideoCategory, newAccountGetHotVideoList } from "@/utils/apis";
import { HOTVIDEO } from "@/utils/staticData";
import {defaultDay} from "@/utils/common";

let _this;
export default {
  name: "HotVideo",
  directives: {
    "el-table-infinite-scroll": elTableInfiniteScroll,
  },
  data() {
    return {
      keyword:'',
      isAll: 0,
      day_type: "day",
      tag: "全部", //分类名称
      typeList: [], //分类列表
      task_condition: "",
      taskList: [], //任务列表
      digg: -1, //点赞区间
      diggOptions: HOTVIDEO.diggOptions,
      duration: -1, //视频时长
      durationOptions: HOTVIDEO.durationOptions,
      gender_type: -1, //男女比例
      genderOptions: HOTVIDEO.genderOptions,
      age_type: -1,
      ageOptions: HOTVIDEO.ageOptions,
      //分页
      adminPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      sort: {
        type: "",
        order: "",
      },
      order: "",
      params: {
        day_type: "day",
        day: "",
        order_by: "synthesize",
        star_category: "",
        page: 0,
        size: 10,
      },
      timer: -1,
      flag: true,
      showScrollLoading: false,
      timeFilterOptions: HOTVIDEO.timeFilterOptions,
      loading: true,
    };
  },
  mounted() {
    _this = this;
    this.getTaskType();
    this.getAllClass();
  },
  computed: {
    /**滚动提示语 */
    scrollHint() {
      return "加载中...";
    },
    /** 经过筛选显示在页面的列表 */
    fullTaskList() {
      let { sort, taskList } = this;
      let newList = [...taskList];
      if (sort.order) {
        let key = sort.type;
        let sortFun =
          sort.order == "descending" ? (before, after) => before[key] - after[key] : (before, after) => after[key] - before[key];
        newList = newList.sort(sortFun);
      }
      return newList;
    },
    /**当前的榜单 */
    currentListType() {
      let { day_type } = this.params;
      return this.timeFilterOptions[day_type];
    },
  },
  filters: {
    /** 判断选中的分类tag */
    categoryStyle(item, nowTag) {
      return {
        current: item == nowTag || (item == "全部" && nowTag == ""),
      };
    },
  },
  watch: {
    "params.day_type": {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
            console.log("current", this.currentListType)
          // this.params.day = newVal === 'day' ? this.currentListType[1].value : this.currentListType[0].value;
          this.params.day = newVal === 'day' ? defaultDay() : this.currentListType[0].value;
        }
      },
      immediate: true,
    },
  },
  methods: {
    load() {
      this.loading = true;
      this.showScrollLoading = true;
      this.timer && window.clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        _this.getAllClass(true);
      }, 500);
    },
    toggleDayType(e) {
      this.params.day = e;
      // console.log(e);
      // console.log(this.params);
      // this.getAllClass();
    },
    getTaskType() {
      newAccountGetVideoCategory().then((res) => {
        if (res.code === 200) {
          this.typeList = res.data;
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    chooseTag(item) {
      // this.tag = item
      this.adminPages.currentPageNum = 1;
      this.params.star_category = item == "全部" ? "" : item;
      this.getAllClass();
    },
    getListByTime() {
      this.getAllClass();
    },
    // 获取全部视频列表
    getAllClass(isAppend = false) {
      if (this.flag) {
        this.flag = false;
        this.loading = true;
        let { params } = this;

        params.page = isAppend ? params.page + 1 : 1;
        params.keyword = this.keyword
          console.log("params", params)
        newAccountGetHotVideoList(params).then((res) => {
          if (res.code == 200) {
            this.taskList = isAppend ? this.taskList.concat(res.data) : res.data;
            if(!isAppend){
              this.$nextTick(()=>{
                this.$refs["multipleTable"].bodyWrapper.scrollTop = 0;

              })
            }

            // this.taskList = [...this.taskList, ...res.data];
            this.showScrollLoading = false;
            this.loading = false;
            // this.adminPages.total = res.data.total;
          } else {
            this.$message({
              type: "error",
              message: res.message,
              duration: 1000,
            });
          }
          this.flag = true;
        });
      }
    },
    //分页
    adminCurrentChange(val) {
      this.adminPages.currentPageNum = val;
      this.getAllClass();
    },
    //跳到详情页
    changeToDetail(row) {
      this.$router.push({
        path: "/student/hot/videoDetail",
        query: {
          aweme_id: row.aweme_id,
          author_id: row.author_id,
        },
      });
    },
    //跳到粉丝详情
    jumpToFansData(row) {
      this.$router.push({
        path: "/student/hot/videoDetail",
        query: {
          aweme_id: row.aweme_id,
          author_id: row.author_id,
        },
      });
    },
    //打开视频
    jumpToVideo(row) {
      window.open(row.aweme_url);
    },
    //排序handler
    sortChange({ prop, order }) {
      this.sort.type = prop;
      this.sort.order = order;
      // this.order = column.prop
      // if (column.order === 'ascending') {
      //     this.sort = 'ASC'
      // } else if (column.order === 'descending') {
      //     this.sort = 'DESC'
      // } else {
      //     this.sort = void 0
      // }
      // this.adminPages.currentPageNum = 1
      // this.getAllClass()
    },
  },
};
</script>

<style scoped lang="scss">
.el-int{
  width: 250px;
  margin-left: 20px;
}
.activeClass {
  background: #2338e6;
  color: #ffffff;
}

.company-contain {
  display: flex;
  flex-direction: column;
  background: #f1f0fe;

  .head-box {
    background: #fff;
  }

  .category-list {
    display: flex;
    padding-top: 20px;
    margin: 0 20px 20px;
    border-bottom: 1px solid #dcdfe6;

    .cate-left {
    }

    .cate-content {
      width: 1%;
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      margin-left: -12px;

      .cate-item {
        padding: 0 8px;
        margin: 0 0 10px 12px;
        border: 1px solid transparent;
        cursor: pointer;

        &.current {
          border-color: #1e33e3;
          color: #1e33e3;
        }
      }
    }
  }

  .time-box {
    display: flex;
    align-items: center;
    .day-type {
      margin-right: 30px;
    }
    .radio-group {
      ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        background-color: #2338e6;
        border-color: #2338e6;
      }

      ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner:hover {
        color: #fff;
      }

      ::v-deep .el-radio-button__inner:hover {
        color: #2338e6;
      }
    }
  }
}

.el-table {
  margin-top: 20px;
  flex: 1;
  height: 1%;
  min-height: calc(100vh - 460px);
  ::v-deep .el-table__body-wrapper {
    height: calc(100vh - 350px) !important;
  }
}

.page-content {
  background: #fff;
}

.right-row-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}

.filter-box {
  display: flex;
  // margin-bottom: 20px;

  .filter-item {
    margin-left: 20px;

    .light-title {
      color: #a4a4a4;
    }
  }
}

.operation-content {
  display: flex;
  justify-content: center;
}

.scroll-hint {
  padding: 12px;
  font-size: 14px;
  text-align: center;
}
</style>
